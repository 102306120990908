<template>
  <div class="home">
    <intro-block id="intro" class="mb-16"></intro-block>

    <feature-block id="features" class="py-16"></feature-block>

    <video-block id="video" class="py-16"></video-block>

    <div class="py-16"></div>

    <partners-block class="py-4"></partners-block>

    <div class="py-16"></div>

    <pricing-block></pricing-block>
  </div>
</template>

<script>
import IntroBlock from '@/views/home/components/intro/IntroBlock.vue'
import FeatureBlock from '@/views/home/components/FeatureBlock';
import PartnersBlock from '@/views/home/components/partners/PartnersBlock';
import PricingBlock from '@/views/home/components/pricing/PricingBlock';
import VideoBlock from '@/views/home/components/VideoBlock.vue';

export default {
  name: 'Home',
  components: {
    PartnersBlock,
    FeatureBlock,
    IntroBlock,
    PricingBlock,
    VideoBlock,
  },
  data: function () {
    return {
      observer: null,
    };
  },
  mounted() {

  }
}
</script>

<style scoped>
</style>
