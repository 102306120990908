<template>
  <v-container id="features">
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <span class="text-h5 text-md-h4 font-weight-bold d-flex flex-column">{{ $t('home.selected_features') }}</span>
      </v-col>
    </v-row>

    <v-row class="pt-2">
      <v-col class="d-flex" cols="12" sm="6" lg="4" v-for="(feature, index) in features" :key="index">
        <feature-card :feature="feature" image-height="150" text-height="200">
          <template v-slot:card-actions>
            <v-btn to="/features" text color="primary">
              {{ $t('home.learn_more') }}
            </v-btn>
          </template>
        </feature-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import features from '@/data/features.data';
import FeatureCard from '@/views/features/FeatureCard';

export default {
  name: 'FeatureBlock',
  components: {FeatureCard},
  computed: {
    features() {
      return features.filter(f => f.popular);
    }
  },
}
</script>

<style scoped>

</style>
