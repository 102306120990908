<template>
  <v-sheet class="primary lighten-4 elevation-3">
    <v-container id="container" class="ma-0">
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="11" md="9" lg="6">
          <v-row class="d-flex justify-center">
            <v-col class="d-flex flex-column text-center text-h6" cols="12" md="10" lg="12">
              <span>{{ $t('home.partners_subtitle') }}</span>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center my-10">
            <v-col class="parner-col d-flex justify-center align-center pa-6" cols="12" sm="6" md="6" lg="6" v-for="(img, i) in images" :key="i">
              <v-img class="partner-img" max-height="100%" max-width="100%" contain :src="img"></v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center pt-7">
              <span v-html="$t('home.become_partner')"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'PartnersBlock',
  data: function () {
    return {
      images: [
        require('@/views/home/components/partners/assets/tbs.png'),
        require('@/views/home/components/partners/assets/LB_logo.svg'),
        require('@/views/home/components/partners/assets/nho.png'),
        require('@/views/home/components/partners/assets/Kundetelling_logo_2019.png'),
      ],
    }
  },
}
</script>

<style scoped>
.parner-col {
  height: 100px;
  max-height: 100px;
}

#container {
  max-width: initial;
}
.partner-img {
  max-width: 80vw;
}
</style>
