<template>
  <div id="intro-block" class="d-flex flex-column align-center">
    <div id="slant" class="secondary"></div>

    <div id="main-content" class="d-flex flex-column align-center justify-center mt-8">
      <img id="logo" class="mt-10 mb-2" src="./assets/logo.png">
      <div id="main-text" class="text-h5 text-md-h4 font-weight-light text-center">{{ $t('home.slogan') }}</div>
    </div>

<!--    https://mediamodifier.com/mockup/apple-devices-mockup-template/583-->
    <v-img id="devices" contain lazy-src="./assets/devices-lazy.png" src="./assets/devices.png"></v-img>
  </div>
</template>

<script>
export default {
  name: 'IntroBlock',
}
</script>

<style scoped>
#intro {
  position: relative;
  height: 100vh !important;
  padding-bottom: 100px;
}

#logo {
  max-height: 60px;
}

#slant {
  width: 100vw;
  position: absolute;
  bottom: 0;
  right: 0;
}

#main-content {
  height: 50vh;
}

#main-text {
  max-width: 85vw;
}

@media (max-width: 599px) {
  #devices {
    max-width: 95vw;
  }

  #slant {
    height: 30vmax;
    clip-path: polygon(0 0, 100% 40%, 100% 100%, 0 100%);
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  #devices {
    max-width: 75vw;
  }

  #slant {
    height: 30vmax;
    clip-path: polygon(0 0, 100% 40%, 100% 100%, 0 100%);
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  #devices {
    max-width: 65vw;
  }

  #slant {
    height: 26vmax;
    clip-path: polygon(0 0, 100% 60%, 100% 100%, 0 100%);
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  #devices {
    max-width: 60vw;
  }

  #slant {
    height: 22vmax;
    clip-path: polygon(0 0, 100% 60%, 100% 100%, 0 100%);
  }
}

@media (min-width: 1904px) {
  #devices {
    max-width: 50vw;
  }

  #slant {
    height: 15vmax;
    clip-path: polygon(0 0, 100% 60%, 100% 100%, 0 100%);
  }
}
</style>
