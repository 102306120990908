<template>
  <div id="pricing">
    <v-container class="secondary lighten-1 elevation-3">
      <v-row class="d-flex flex-column flex-sm-row justify-center">
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <v-img max-height="240px" contain src="./assets/undraw_informed_decision_p2lh.svg"></v-img>
        </v-col>
        <v-col cols="12" md="8" class="d-flex flex-column justify-center">
          <span class="text-h5">{{ $t('main.pricing_title') }}</span>
          <span class="text-pre-wrap">{{ $t('main.pricing_text') }}</span>

          <v-btn to="/contact" class="mt-5 align-self-start" color="primary">{{ $t('main.menu_contact') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PricingBlock',
}
</script>

<style scoped>
</style>
