<template>
  <v-container id="video">
    <v-row>
      <v-col cols="12">
        <span class="text-h7 text-sm-h6 mb-4 text-md-h4 font-weight-bold d-flex flex-column">{{ $t('home.video_title') }}</span>
      </v-col>
    </v-row>

    <div id="frame-container">
      <iframe src="https://www.youtube-nocookie.com/embed/-1tbit8NILw?autoplay=0&mute=0&loop=1"
              id="video-frame"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
      </iframe>
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'VideoBlock',
  computed: {},
}
</script>

<style scoped>
#frame-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

#video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  #frame-container {
    margin-bottom: -15%;
  }

  #video-frame {
    height: 100%;
  }
}

@media only screen and (min-width: 600px) {
  #frame-container {
    margin-bottom: -20%;
  }

  #video-frame {
    height: 90%;
  }
}

@media only screen and (min-width: 768px) {
  #frame-container {
    margin-bottom: -20%;
  }

  #video-frame {
    height: 80%;
  }
}

@media only screen and (min-width: 992px) {
  #frame-container {
    margin-bottom: -25%;
  }

  #video-frame {
    height: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  #frame-container {
    margin-bottom: -25%;
  }

  #video-frame {
    height: 60%;
  }
}
</style>
